import React, { Component } from "react";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="center">
        <p>&copy; Ing. Marlon Ralda 2024.</p>
      </div>
    </footer>
  );
};

export default Footer;
